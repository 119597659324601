import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Logo from "../images/header/logo_small.svg"
import Prev from "../images/how_does_it_work/left.svg"
import axios from "axios"
import emailjs from "emailjs-com"

const AnnualStyle = styled.section`
  padding-bottom: 96px;
  background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
  padding: 40px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  color: #423d33;
  overflow-x: hidden;
  overflow-y: hidden;

  min-height: 200%;
  height: 100vh;

  margin-top: 0;

  .form-warning {
    font-size: 14px;
    line-height: 24px;
    color: #a23dad;
    margin-bottom: 24px;
    width: 200px;
  }
  button {
    margin-right: 0px;
  }
  ul {
    padding: 0px;
  }

  @media (max-width: 767px) {
    .image-box {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .form-warning {
      margin-bottom: 0;
      transform: translateY(34);
    }
  }

  @media (min-width: 1024px) {
    .form-warning {
      transform: translateY(40px);
    }
  }

  .thank-you-on-request {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
    color: #207227;
    width: 218px;
    height: 48px;
    padding: 0;
    display: none;
    margin-right: 12px;
    order: 2;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin: 24px 0px;
    margin-top: 28px;
  }

  ul {
    list-style-type: none;
    width: 100%;
  }

  p {
    font-size: 18px;
    margin-bottom: 0;
    line-height: 24px;
    font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on, "ss07" on;
  }

  .img {
    margin: 24px 0px;
  }

  .introduction {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
  }

  ul {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #625d52;
    margin-bottom: 24px;
    padding-right: 48px;
    margin-top: 24px;
    li {
      margin-bottom: 16px;
    }
  }

  input {
    background: #fcfdfc;
    width: 100%;
    height: 48px;
    border: 1px solid #625d52;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 24px;
    padding-left: 16px;
    transition: border-color 0.4s ease;
    color: #625d52;
  }

  input:focus {
    outline: none;
    border: 1px solid #57ae5b;
    transition: border-color 0.4s ease;
    color: #625d52;
  }

  input:focus::placeholder {
    color: #625d52;
    opacity: 1;
    transition: border-color 0.4s ease;
  }

  button {
    border-radius: 4px;

    width: 102px;

    height: 56px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    background: #2f8132;
    color: #fcfdfc;
    border: 0;
    cursor: pointer;

    order: 2;
  }

  .third {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 96px;
  }

  .back-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 128px;
    .home {
      margin-left: 16px;
      font-weight: bold;
      font-size: 16px;
      line-height: 14px;
      color: #625d52;
    }
  }

  @media (max-width: 1439px) {
    .other {
      display: none;
    }
  }

  @media (min-width: 768px) {
    overflow-y: scroll;
    padding: 0;
    .thank-you-on-request {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
      color: #207227;
      width: 218px;
      height: 48px;
      padding: 0;
      display: none;
      margin-left: 106px;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100vw;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }

    .introduction,
    ul,
    form,
    .back-button,
    button,
    .image-box {
      width: 321px;
    }

    .back-button {
      order: -1;
    }
    .img {
      width: 535px;
      height: 296px;
      margin-top: 30px;
    }
    .image-box {
      margin-right: 32px;
      padding-left: 0;
    }

    .row {
      align-items: start;
    }

    form {
      width: 324px;
      padding: 0;
      height: 352px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .back-button {
      transform: translateX(-32px);
    }

    .title {
      font-size: 32px;
      line-height: 40px;
      margin-top: 36px;
      margin-bottom: 32px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
    }
    .logo {
      margin-top: 24px;
    }
    .introduction {
      padding: 0;
      margin-right: 24px;
    }
    ul {
      font-size: 18px;
      line-height: 24px;
      margin-left: 0;
      padding-right: 12px;
      margin-top: 32px;
    }

    button {
      padding: 0;
      width: 102px;
      margin-left: 216px;
      margin-right: 0;
      margin-top: 0px;
    }
    .first,
    .second {
      margin-top: 32px;
    }

    ul,
    form {
      margin: 0px;
      margin-right: 29px;
      margin-left: 3px;
    }
    ul {
      padding: 0px;
      margin-right: 20px;
    }
  }

  @media (min-width: 1024px) {
    .thank-you-on-request {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
      color: #207227;
      width: 218px;
      height: 48px;
      padding: 0;
      display: none;
      margin-left: 166px;
    }

    .introduction,
    ul,
    form,
    .back-button,
    button,
    .image-box {
      width: 384px;
    }

    form {
      justify-content: flex-start;
      height: 100%;
    }

    .img {
      width: 384px;
      height: 213px;
    }

    button {
      width: 102px;
      margin-right: 0;
      margin-left: 282px;
    }

    .back-button {
      transform: translateX(-26px);
    }

    .third {
      margin-bottom: 128px;
    }

    form {
      margin-left: 0px;
      margin-right: 32px;
    }

    ul {
      padding-left: 20px;
    }

    ul {
      padding-right: 32px;
      padding-left: 0;
      margin-top: 0;
      margin-right: 24px;
      padding: 0;
    }
  }

  @media (min-width: 1440px) {
    .thank-you-on-request {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      font-feature-settings: "ss04" on, "ss03" on, "ss02" on, "ss06" on,
        "ss07" on;
      color: #207227;
      width: 292px;
      height: 64px;
      padding: 0;
      display: none;
      margin-left: 220px;
    }

    .introduction,
    ul,
    form,
    .back-button,
    button,
    .image-box {
      width: 512px;
    }

    .image-box {
      margin: 0;
      padding: 0;
    }

    .title {
      margin: 32px 0;
      margin-top: 36px;
    }

    .logo {
      margin-top: 32px;
    }
    .first {
      padding-right: 32px;
    }

    .img {
      width: 448px;
      height: 248px;
      width: 100%;
      height: auto;
    }
    form {
      height: 320px;
      justify-content: center;
      margin-right: 24px;
    }
    .image-box {
      height: 100%;
    }

    .second,
    .first {
      margin-top: 64px;
    }

    li {
      margin-bottom: 10px;
      margin-top: 8px;
      width: 512px;
    }
    ul {
      width: 512px;
    }

    button {
      width: 102px;
      margin-left: 410px;
      transition: background-color 1s;
    }

    form {
      margin-right: 26px;
    }
    ul {
      margin-left: 0px;
    }
  }

  .gray {
    background: #d3cec4;
    color: #a39e93;
  }

  input:hover {
    color: #a39e93;
    transition: border-color 0.4s ease, color 0.4s ease;
  }

  input:focus {
    transition: border-color 0.4s ease, color 0.4s ease;
  }

  input:valid {
    background-color: #fcfdfc;
    border: 1px solid #57ae5b;
    color: #57ae5b;
    transition: border-color 0.4s ease, color 0.4s ease;
  }

  input:invalid {
    background-color: #fcfdfc;
    color: #ad4bb8;
    transition: border-color 0.4s ease, color 0.4s ease;
    border: 1px solid #ad4bb8;
    input:focus {
      color: #625d52;
    }
  }

  input:focus {
    border: 1px solid #625d52;
    color: #625d52;
  }

  .preclick {
    border: 1px solid #625d52 !important;
    color: #b8b2a7 !important;
  }
`

const Shedule = () => {
  // let formReady = false

  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "Ascalia_email_sending",
        "Ascalia_template",
        e.target,
        "user_9ecFjFTLO0zLsQXh2OCfX"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  const name = useRef(null)
  const organization = useRef(null)
  const email = useRef(null)
  const phone = useRef(null)
  const button = useRef(null)
  const warning = useRef(null)
  const form = useRef(null)
  const third_row = useRef(null)
  const thankYou = useRef(null)

  const [formReady, setFormReady] = useState(() => false)

  useEffect(() => {
    warning.current.style.opacity = "0"

    name.current.addEventListener("mousedown", () => {
      name.current.classList.remove("preclick")
      requestStatus()
    })
    organization.current.addEventListener("mousedown", () => {
      organization.current.classList.remove("preclick")
      requestStatus()
    })
    email.current.addEventListener("mousedown", () => {
      email.current.classList.remove("preclick")
      requestStatus()
    })
    phone.current.addEventListener("mousedown", () => {
      phone.current.classList.remove("preclick")
      requestStatus()
    })

    name.current.classList.add("preclick")
    organization.current.classList.add("preclick")
    email.current.classList.add("preclick")
    phone.current.classList.add("preclick")

    const requestStatus = () => {
      if (
        name.current.checkValidity() &&
        organization.current.checkValidity() &&
        email.current.checkValidity() &&
        phone.current.checkValidity()
      ) {
        button.current.style.color = "#E3F9E5"
        button.current.style.background = "#2F8132"
        button.current.style.transition = "all 1s"
        setFormReady(form => (form = true))
        warning.current.style.opacity = "0"
      } else {
        button.current.style.color = "#a39e93"
        button.current.style.background = "#d3cec4"
        button.current.style.transition = "all 1s"
        setFormReady(form => (form = false))
      }

      if (
        !name.current.checkValidity() &&
        !name.current.classList.contains("preclick")
      ) {
        warning.current.innerHTML = "* Please enter a name"
        warning.current.style.opacity = "1"
      }

      if (
        !organization.current.checkValidity() &&
        !organization.current.classList.contains("preclick")
      ) {
        warning.current.innerHTML = "* please enter a organization"
        warning.current.style.opacity = "1"
      }

      if (
        !phone.current.checkValidity() &&
        !phone.current.classList.contains("preclick")
      ) {
        warning.current.innerHTML = "* please enter your phone"
        warning.current.style.opacity = "1"
      }

      if (
        !email.current.checkValidity() &&
        !email.current.classList.contains("preclick")
      ) {
        warning.current.innerHTML = "* please enter valid email"
        warning.current.style.opacity = "1"
      }
    }

    name.current.addEventListener("input", requestStatus)
    organization.current.addEventListener("input", requestStatus)
    email.current.addEventListener("input", requestStatus)
    phone.current.addEventListener("input", requestStatus)

    button.current.addEventListener("mouseenter", () => {
      if (formReady) {
        button.current.style.backgroundColor = "#57AE5B"
        button.current.style.transition = "all 1s"
      }
    })

    button.current.addEventListener("mouseleave", () => {
      if (formReady) {
        button.current.style.backgroundColor = "#2F8132"
        button.current.style.transition = "all 1s"
      }
    })
  }, [])

  const sendForm = () => {
    console.log(formReady)
    if (formReady) {
      button.current.style.background = "#57AE5B"
      button.current.style.boxShadow =
        "inset 0px 2px 2px rgba(14, 88, 20, 0.25)"
      button.current.style.display = "none"
      thankYou.current.style.display = "block"

      // axios.post(
      //   "https://joxergoestopoland.ie/EmailSending/mailAscalia.php",
      //   `organization=${organization.value}&name=${name.value}&cc=${email.value}&phone=${phone.value}`
      // )

      // axios.post(
      //    "https://joxergoestopoland.ie/EmailSending/index.php",
      //   `week=first week&subject=this is random subject`
      // )

      axios.post(
        "https://epostman.email/EmailSending/gmail.php",
        `email=${email.current.value}`
      )

      // axios.post(
      //   "https://joxergoestopoland.ie/EmailSending/gmail.php",
      //   `email=${email.current.value}`
      // )

      // form.submit()

      if (window.innerWidth < 768) {
        third_row.current.style.flexDirection = "column"
        thankYou.current.style.order = "-1"
        thankYou.current.style.marginLeft = "12px"
        thankYou.current.style.marginBottom = "12px"
      }
    }
  }

  return (
    <AnnualStyle>
      <div className="center">
        <div className="row first tablet">
          <div className="introduction">
            <Logo className="logo"></Logo>
            <div className="title">Get in touch with us</div>
            <p>
              We would love to get in touch. You can reach us by email, by
              phone, or by filling out our contact form and we will get back to
              you.
            </p>
          </div>
          <div className="image-box">
            <StaticImage
              tag="section"
              className="img"
              quality={95}
              layout="constrained"
              placeholder="blurred"
              src="../images/sheldue/img1.png"
              alt="Sigfox logo"
            ></StaticImage>
          </div>
        </div>
        <div className="row second tablet">
          <ul>
            <li>phone: + 44 (20) 37693114</li>
            <li>email: info@ascalia.io</li>
          </ul>
          <form
            className="demo-form"
            id="form1"
            ref={form}
            // onSubmit={sendEmail}
          >
            <input
              className="name-input"
              type="text"
              placeholder="Name"
              name="name"
              required
              ref={name}
            />
            <input
              className="organization-input"
              type="text"
              placeholder="Organization"
              name="organization"
              ref={organization}
              required
            />
            <input
              className="email-input"
              type="email"
              placeholder="Email"
              name="email"
              ref={email}
              required
            />
            <input
              className="phone-input"
              type="tel"
              placeholder="Phone"
              name="phone"
              ref={phone}
              required
            />
            {/* <button type="submit" form="form1">
              {" "}
              send email
            </button> */}
            <div ref={warning} className="form-warning">
              {" "}
              *Warning{" "}
            </div>
          </form>
        </div>
        <div className="row third tablet" ref={third_row}>
          <button
            className="button-request gray"
            ref={button}
            onClick={sendForm}
          >
            Sent
          </button>
          <div ref={thankYou} className="thank-you-on-request">
            Thank you for your enquiry. We will contact you shortly.
          </div>
          <div
            className="back-button"
            onClick={() => {
              navigate("/")
            }}
          >
            <Prev className="test" />
            <div className="home">Home</div>
          </div>
        </div>
      </div>
    </AnnualStyle>
  )
}

export default Shedule
