import * as React from "react"
import styled from "styled-components"
import { Global, css } from "@emotion/react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Sheldue from "../components/shedule"

const AnnualStyle = styled.section`
  font-family: "Roboto";
  font-style: normal;
`

const IndexPage = props => {
  return (
    <AnnualStyle>
      <Global
        styles={css`
          body {
            margin: 0;
          }
          html {
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />

      {/* <Header></Header> */}
      <Sheldue></Sheldue>
    </AnnualStyle>
  )
}

export default IndexPage
